import { DiscountHotelPriceOffer } from '@/interfaces/neusta-discount-price';
import { getDefaultCurrency } from '@utils/environmentUtils';
import { formatPrice } from '@/js/utils/priceUtils';
import { computed, Ref } from 'vue';
import { HistoricalPriceData } from '@/interfaces/common';

const MINIMUM_DISCOUNT_PERCENTAGE = 5;

export const useDiscount = function (price: Ref<DiscountHotelPriceOffer | undefined>) {
	const hasDiscount = computed(() => !!price.value && price.value.relativeDiscountPercent && price.value.relativeDiscountPercent.toAvgPrice >= MINIMUM_DISCOUNT_PERCENTAGE);
	const badgeText = computed(() => {
		if (!price.value) return '';
		return `${price.value.relativeDiscountPercent?.toAvgPrice}% günstiger`;
	});

	const discountPrice = computed(() => {
		if (!price.value) return -1;
		return price.value.referencePrice ? price.value.referencePrice.avgPrice - price.value.currentPrice : price.value.currentPrice;
	});
	const discountCurrency = getDefaultCurrency();
	const tooltipText = computed(() => {
		if (!price.value) return '';
		return `Aktuell bietet dieses Hotel eine Ersparnis von ${formatPrice(discountPrice.value, discountCurrency)} p.P. Der Referenzpreis von ${formatPrice(price.value.referencePrice?.avgPrice ?? null, discountCurrency)} p.P. basiert auf dem durchschnittlichen ab-Preis, der für dieses Hotel bei gleichen Suchkriterien in den letzten 30 Tagen verfügbar war. Verglichen wurden die Preise für gleiche Reisedauer, Reisedatum (mit einer Toleranz von +/- 15 Tagen), Abflughafen, Zimmertyp, Verpflegung und Reisende.`;
	});

	return { badgeText, tooltipText, hasDiscount };
};

export function useHistoricalPriceData(price: Ref<HistoricalPriceData | undefined>, currencySymbol: string) {
	const hasDiscount = computed(() => !!price.value && price.value?.relativeDiscountPercent && price.value.relativeDiscountPercent.toAvgPrice >= MINIMUM_DISCOUNT_PERCENTAGE);
	const discount = computed(() => price.value?.relativeDiscountPercent?.toAvgPrice ?? 0);

	const priceAverage = computed(() => price.value?.referencePrice?.avgPrice);
	const averagePriceFormatted = computed((): string => (priceAverage.value ? formatPrice(priceAverage.value, currencySymbol) : ''));

	return { hasDiscount, averagePriceFormatted, discount, priceAverage }
}
