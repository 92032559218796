<template>
	<component
		:is="parentElement"
		:class="['chip-base', blockClassModifiers]"
		v-bind="defaultAttributes"
		@click="onChipClick"
		v-on="defaultListeners"
	>
		<span class="chip-base__icon">
			<slot name="icon">
				<BaseIcon
					v-if="iconName"
					:name="iconName"
					aria-hidden="true"
				/>
			</slot>
		</span>
		<span class="chip-base__label">
			<slot name="label">{{ label }}</slot>
		</span>
		<slot />
		<input
			@click.stop
			v-if="isSelector"
			:type="as"
			class="chip-base__input"
			v-model="model"
			:name
			:value
			:aria-label="label"
		/>
		<CloseButton
			v-if="withDelete"
			class="chip-base__delete"
			inherit-styles
			:aria-label="`Delete ${label}`"
			@click.stop="onDelete"
		/>
	</component>
</template>
<script lang="ts" setup generic="TVal">
import { computed } from 'vue';
import CloseButton from '../CloseButton/CloseButton.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import type { IconNames } from '@lmt-rpb/BaseIcon/svg';

interface Props {
	iconName?: IconNames;
	label: string;
	withDelete?: boolean;
	selected?: boolean;
	name?: string;
	value?: TVal;
	as?: 'div' | 'checkbox' | 'radio';
}

const { withDelete = false, selected = false, as = undefined, value, label } = defineProps<Props>();
const emit = defineEmits(['delete-click', 'click']);
const model = defineModel();

const isSelector = computed(() => as === 'checkbox' || as === 'radio');
const parentElement = computed(() => isSelector.value ? 'label' : 'div');
const defaultAttributes = computed(() => {
	if (!isSelector.value) {
		return {
			role: 'button',
			tabindex: 0,
			'aria-label': label,
			'aria-selected': selected,
			'key-dow': onKeyDown,
		};
	}
	return {};
});

const defaultListeners = computed(() => {
	if (!isSelector.value) {
		return {
			keydown: onKeyDown,
		};
	}
	return {};
});

const blockClassModifiers = computed(() => ({
	'chip-base--selected': selected,
	'chip-base--selector': isSelector.value,
}));

const onChipClick = () => {
	emit('click');
};

function onKeyDown(event: KeyboardEvent) {
	if (event.key === 'Enter' || event.key === ' ') {
		onChipClick();
	}
};

const onDelete = () => {
	emit('delete-click');
};
</script>

<style scoped lang="scss">
.chip-base {
	display: inline-flex;
	gap: 0.8rem;
	align-items: center;
	border: .2rem solid $color-primary;
	border-radius: $border-radius-xx-large;
	padding: .8rem 1.6rem;
	cursor: pointer;
	background-color: $color-white;
	height: 3.6rem;
	color: $color-primary;
	transition: background-color 200ms ease;
	margin: 0;

	&__label {
		font-size: $font-small-1;
		font-weight: $font-weight-bold;
		line-height: 1.25;
		color: inherit;
		transition: color 200ms ease;
	}

	&__input {
		@include sr-only;
	}

	&__icon {
		height: $font-small-1;
		& > * {
			width: auto;
			fill: currentcolor;
			color: currentcolor;
			font-size:  $font-small-1;
			font-weight: 700;
		}

		& > svg * {
			fill: currentcolor;
		}

		&:empty {
			display: none;
		}
	}

	&:hover,
	&:focus-visible,
	&:focus-within {
		background: $color-primary-l6;
	}

	&--selected, &:has(.chip-base__input:checked) {
		background-color: $color-primary;
		color: $color-white;

		&:hover,
		&:focus-visible,
		&:focus-within {
			border-color: $color-primary-l0 !important;
			background: $color-primary-l0;
		}
	}

}
</style>