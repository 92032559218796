import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list-filters" }
const _hoisted_2 = { class: "list-filters__content" }

import BoardTypeFilter from '@lmt-rpb/BoardTypeFilter/BoardTypeFilter.vue'
import HotelTransferFilter from '@lmt-rpb/HotelTransferFilter/HotelTransferFilter.vue';
import RecommendationRateFilter from '@lmt-rpb/RecommendationRateFilter/RecommendationRateFilter.vue';
import PriceFilter from '@lmt-rpb/PriceFilter/PriceFilter.vue';
import HotelCategoryFilter from '@lmt-rpb/HotelCategoryFilter/HotelCategoryFilter.vue';
import SeaViewFilter from '@lmt-rpb/SeaViewFilter/SeaViewFilter.vue';
import CustomerRatingFilter from '@lmt-rpb/CustomerRatingFilter/CustomerRatingFilter.vue';
import DestinationFilter from '@lmt-rpb/DestinationFilter/DestinationFilter.vue';
import DirectFlightFilter from '@lmt-rpb/DirectFlightFilter/DirectFlightFilter.vue';
import FlyingTimeFilter from '@lmt-rpb/FlyingTimeFilter/FlyingTimeFilter.vue';
import RoomTypeFilter from '@lmt-rpb/RoomTypeFilter/RoomTypeFilter.vue';
import MostPopularFilters from '@lmt-rpb/MostPopularFiltersNew/MostPopularFiltersNew.vue';
import OperatorField from '@lmt-rpb/OperatorField/OperatorField.vue';
import type { SearchFormDataType } from '@interfaces/search-form';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import FilterContainer from '@lmt-rpb/FilterContainer/FilterContainer.vue';
import {
	computed, onMounted, ref,
} from 'vue';
import FlexFilter from '@lmt-rpb/FlexFilter/FlexFilter.vue';
import DealsFilter from '@lmt-rpb/Organisms/DealsFilter/DealsFilter.vue';
import { dealsFilterClientTitle } from '@lmt-rpb/Organisms/DealsFilter/dealsFilterHelper';
import { useStore } from '@/components/common/store';
import { isClientGermany } from '@/js/utils/environmentUtils';

interface Props {
	forceDesktop?: boolean
	destinationLabel?: string
	listType: 'hotel' | 'region' | 'offer',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ListFilters',
  props: {
    forceDesktop: { type: Boolean, default: false },
    destinationLabel: { default: '' },
    listType: {}
  },
  setup(__props: any) {


const props = __props;

const dealsFilterTitle = dealsFilterClientTitle();

const store = useStore();
const formData = computed((): SearchFormDataType => store.state.searchMask);
const locationType = computed((): string => store.state.config.locationType);

const showDestinationFilter = computed((): boolean => props.listType === 'hotel' && locationType.value !== 'CITY');
const destinationTypes = computed(() => store.state.types.destination);

const isHotelListPage = computed(() => props.listType === 'hotel');
const isOfferList = computed(() => props.listType === 'offer');

const openAdditionalFilters = ref(Boolean(formData.value.additionalCheckboxFilters?.ratingAttributes?.length || formData.value.additionalCheckboxFilters?.hotelAttributes?.length));

const isHotelOnly = computed((): boolean => formData.value.onlyHotel);

const operatorTypes = computed(() => store.state.types.operator);

const showSuns = isClientGermany();

const currencyType = computed(() => (store.getters.getCurrencyType));

const tourOperatorLoading = computed((): boolean => store.state.tourOperatorLoading);

const destinationFilterLoading = computed((): boolean => store.state.destinationFilterLoading);

const setAccommodationType = (onlyHotel: boolean): void => {
	store.commit('searchMask/updateFormData', { onlyHotel });
};

onMounted(() => {
	// this is needed for setting the isDesktop property to true in Storybook
	if (props.forceDesktop) {
		store.state.config.isDesktop = true;
	}

	if (isHotelOnly.value) {
		setAccommodationType(true);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "list-filters__header" }, " Suchergebnisse filtern ", -1 /* HOISTED */)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (isHotelListPage.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 0,
              title: _unref(dealsFilterTitle),
              "with-divider": false
            }, {
              default: _withCtx(() => [
                _createVNode(DealsFilter, { class: "list-filters__deals" })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["title"]))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, { "with-divider": isHotelListPage.value }, {
          default: _withCtx(() => [
            _createVNode(FlexFilter)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["with-divider"]),
        (!isHotelOnly.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(DirectFlightFilter, {
                  title: "Flug",
                  class: "list-filters__direct-flight"
                }),
                _createVNode(FlyingTimeFilter, { class: "list-filters__flight" })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 2 }, {
              default: _withCtx(() => [
                _createVNode(PriceFilter, {
                  "default-value": formData.value.maxPrice,
                  currency: currencyType.value
                }, null, 8 /* PROPS */, ["default-value", "currency"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, null, {
          default: _withCtx(() => [
            _createVNode(RoomTypeFilter, {
              "is-hotel-page": isOfferList.value,
              class: "list-filters__room-type"
            }, null, 8 /* PROPS */, ["is-hotel-page"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(FilterContainer, null, {
          default: _withCtx(() => [
            _createVNode(SeaViewFilter)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(FilterContainer, null, {
          default: _withCtx(() => [
            _createVNode(BoardTypeFilter, {
              "is-hotel-page": isOfferList.value,
              class: "list-filters__board-types"
            }, null, 8 /* PROPS */, ["is-hotel-page"])
          ]),
          _: 1 /* STABLE */
        }),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 3 }, {
              default: _withCtx(() => [
                _createVNode(MostPopularFilters, {
                  class: "list-filters__most-popular",
                  filters: formData.value.mostPopularFilters
                }, null, 8 /* PROPS */, ["filters"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 4 }, {
              default: _withCtx(() => [
                _createVNode(HotelCategoryFilter, { "show-suns": _unref(showSuns) }, null, 8 /* PROPS */, ["show-suns"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 5 }, {
              default: _withCtx(() => [
                _createVNode(RecommendationRateFilter)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 6 }, {
              default: _withCtx(() => [
                _createVNode(CustomerRatingFilter)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, null, {
          default: _withCtx(() => [
            _createVNode(HotelTransferFilter)
          ]),
          _: 1 /* STABLE */
        }),
        (showDestinationFilter.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 7 }, {
              default: _withCtx(() => [
                (destinationFilterLoading.value)
                  ? (_openBlock(), _createBlock(SkeletonLoader, {
                      key: 0,
                      class: "list-filters__geo-skeleton",
                      "loader-width": 31.8,
                      "loader-height": 7,
                      "border-radius": '1rem'
                    }))
                  : (_openBlock(), _createBlock(DestinationFilter, {
                      key: 1,
                      "destination-label": _ctx.destinationLabel,
                      "location-type": locationType.value,
                      disabled: !destinationTypes.value || destinationTypes.value.length == 0
                    }, null, 8 /* PROPS */, ["destination-label", "location-type", "disabled"]))
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, null, {
          default: _withCtx(() => [
            (!tourOperatorLoading.value)
              ? (_openBlock(), _createBlock(OperatorField, {
                  key: 0,
                  class: "list-filters__operator",
                  disabled: !operatorTypes.value || operatorTypes.value.length === 0
                }, null, 8 /* PROPS */, ["disabled"]))
              : _createCommentVNode("v-if", true),
            (tourOperatorLoading.value)
              ? (_openBlock(), _createBlock(SkeletonLoader, {
                  key: 1,
                  class: "list-filters__operator-skeleton",
                  "loader-width": 31.8,
                  "loader-height": 7,
                  "border-radius": '1rem'
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ]))
}
}

})