import { getFluxIbeUrl, cloneDeep } from '@utils/utils';
import { determineClient } from '@utils/environmentUtils';
import axios, { AxiosError } from 'axios';
import { OfferType } from '@interfaces/hotel-list-types/hotel-list-types';
import {
	DiscountRequestBody, DiscountHotelPriceOffer, AccommodationOffer, PackageOffer,
} from '@interfaces/neusta-discount-price';
import { Price } from '@/interfaces/checkout/offer-data';
import { getUniqueKey } from './bestOfferForHotelListService';
import { extractTravelTypeFromOffer, Offer, TravelType } from '@/js/utils/offerUtils';
import type { Travelers } from '../types';

const roomTypes = [
	{ id: 1, amadeusValue: 'Apartment', neustaValue: 'Appartement' },
	{ id: 2, amadeusValue: 'Bungalow', neustaValue: 'Bungalow' },
	{ id: 3, amadeusValue: 'DeluxeRoom', neustaValue: 'DeluxeRoom' },
	{ id: 4, amadeusValue: 'DoubleRoom', neustaValue: 'DoubleRoom' },
	{ id: 5, amadeusValue: 'DuplexRoom', neustaValue: 'Duplex' },
	{ id: 6, amadeusValue: 'FamilyRoom', neustaValue: 'FamilyRoom' },
	{ id: 7, amadeusValue: 'HolidayFlat', neustaValue: 'HolidayFlat' },
	{ id: 8, amadeusValue: 'MultiBedRoom', neustaValue: 'MultiBedroom' },
	{ id: 9, amadeusValue: 'SingleRoom', neustaValue: 'SingleRoom' },
	{ id: 10, amadeusValue: 'Studio', neustaValue: 'Studio' },
	{ id: 11, amadeusValue: 'Suite', neustaValue: 'Suite' },
	{ id: 12, amadeusValue: 'SuperiorRoom', neustaValue: 'Superior' },
	{ id: 13, amadeusValue: 'ThreeBedRoom', neustaValue: 'TripleBedroom' },
	{ id: 14, amadeusValue: 'Villa', neustaValue: 'Villa' },
];

const DEFAULT_ADULT_AGE = 35;

export type DiscountRequest = Offer & { Adults: number, Children?: number[] }

function isPackageOffer(offer: AccommodationOffer | PackageOffer, isHotelOnly: boolean): offer is Partial<PackageOffer> {
	return isHotelOnly;
}

type NeustaValidationError = {
	Status: 'ValidationError'
	Errors: Array<{ ID: number } & Record<string, string>>
}

function isValidationError<T>(responseData: NeustaValidationError | T | false): responseData is NeustaValidationError {
	return responseData && !Array.isArray(responseData);
}

function getTravellers(travellerInfo: Travelers) { // fix travellers for hotelbox -- remove store reference for that. Instead add params info.
	const travellers = Array(travellerInfo.adult).fill({ Age: DEFAULT_ADULT_AGE });
	travellerInfo.children.forEach((child) => travellers.push({ Age: child }));
	return travellers;
}

function extractPriceInformation(hotels: Offer[]) {
	return new Map(hotels.map((hotel) => {
		const currentOffer = extractTravelTypeFromOffer(hotel);
		return [
			getUniqueKey({ offerId: currentOffer!.OfferID, iffCode: hotel.Hotel.IffCode }),
			currentOffer!.PriceInformation,
		];
	}));
}

function mapSwissFrancDiscountPrices(discount: DiscountHotelPriceOffer, price: Price) {
	const exchangeRate = price.Converted!.ExchangeRate!;
	const result: DiscountHotelPriceOffer = cloneDeep(discount);
	result.currentPrice = discount.currentPrice * exchangeRate;
	if (discount.referencePrice) {
		result.referencePrice = {
			avgPrice: discount.referencePrice.avgPrice * exchangeRate,
			minPrice: discount.referencePrice.minPrice * exchangeRate,
			maxPrice: discount.referencePrice.maxPrice * exchangeRate
		};
	}
	return result;
}

function extractDataForWasPrice(hotelsData: Offer[], isHotelOnly: boolean) {
	const discountRequest: DiscountRequestBody = {
		TravelType: isHotelOnly ? 'Accommodation' : 'Package',
		SessionID: (Math.random() * 99999).toString(),
		Offers: [],
	};
	discountRequest.Offers = hotelsData.map((hotel) => {
		const {
			PackageOffer: packageOffer,
			Hotel: { IffCode },
			Adults: adult,
			Children: children = []
		} = hotel;

		const currentOffer = extractTravelTypeFromOffer(hotel);

		const offer: Partial<PackageOffer | AccommodationOffer> = {
			OfferID: currentOffer?.OfferID,
			HotelIffCode: IffCode,
			TripDuration: isHotelOnly ? currentOffer?.OvernightStays : currentOffer?.TripDuration,
			StartDate: currentOffer?.StartDate,
			RoomType: getRoomType(currentOffer!.Room),
			BoardType: currentOffer?.Board,
			Travellers: getTravellers({ adult, children }),
			OriginalPrice: currentOffer?.PriceInformation?.Amount,
		};
		if (isPackageOffer(offer, !isHotelOnly)) {
			offer.DepartureAirport = packageOffer?.Flight?.OutboundFlight?.AirportCode;
			offer.ArrivalAirport = packageOffer?.Flight?.InboundFlight?.AirportCode;
		}
		return offer;
	}).filter((offer) => !Object.values(offer).some((value) => !value))
		.filter((offer) => offer.BoardType !== 'AccordingDescription');
	return discountRequest;
}

function getRoomType(roomType: OfferType['Room']) {
	const room = roomTypes.find((type: { amadeusValue: string }) => type.amadeusValue === roomType?.Type);
	return room?.amadeusValue;
}

export function createDiscountRequest(offersData: Offer[], adults: number, children?: number[]): DiscountRequest[] {
	return offersData.map((offer) => ({
		...cloneDeep(offer),
			Adults: adults,
			Children: children
		}));
}

export async function get(offersData: Offer[]) {
	if (!offersData || offersData.length === 0) {
		return [];
	}
	const isHotelOnly = extractTravelTypeFromOffer(offersData[0])?.TravelType === TravelType.Accommodation;
	const hotelsData = extractDataForWasPrice(offersData, isHotelOnly);

	const response = await axios<DiscountHotelPriceOffer[] | false | NeustaValidationError>({
		method: 'POST',
		url: `${getFluxIbeUrl()}/v1/neusta/fetch-was-price`,
		headers: {
			'content-type': 'application/json'
		},
		data: hotelsData
	});

	if (isValidationError(response.data)) {
		throw new AxiosError('ValidationError', undefined, undefined, hotelsData, response);
	}

	if (!response.data || determineClient(window.location.href) !== 'ch') {
		return response.data;
	}
	const prices = extractPriceInformation(offersData);
	return response.data.map((discount) => mapSwissFrancDiscountPrices(discount, prices.get(getUniqueKey(discount))!));
}

export async function getAsMap(offersData: Offer[]) : Promise<Map<string, DiscountHotelPriceOffer>> {
	const offers = await get(offersData);
	if (!offers) {
		return new Map();
	}
	return new Map<string, DiscountHotelPriceOffer>(
		offers.map((offer) => [getUniqueKey(offer), offer])
	);
}
