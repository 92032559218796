import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-bar-core__fields" }

import {
	SearchFormDataType,
} from '@interfaces/search-form';
import AirportFilter from '@lmt-rpb/AirportFilter/AirportFilter.vue';
import DestinationField from '@lmt-rpb/DestinationField/DestinationField.vue';
import OfferDurationField from '@lmt-rpb/OfferDurationField/OfferDurationField.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import TravelDurationField from '@lmt-rpb/TravelDurationField/TravelDurationField.vue';
import TravelersField from '@lmt-rpb/TravelersField/TravelersField.vue';
import {
	scrollIt,
} from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import {
	computed, onMounted, ref
} from 'vue';
import { useStore } from '@/components/common/store';
import TravelTypeSwitch from '../TravelTypeSwitch/TravelTypeSwitch.vue';
import ExpandTransition from '@lmt-rpb/Atoms/ExpandTransition/ExpandTransition.vue';

interface Props {
	variant?: 'standalone' | 'inline';
	showSubmitButton?: boolean;
}

const submitButtonText = 'Suchen';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchBarCore',
  props: {
    variant: { default: 'standalone' },
    showSubmitButton: { type: Boolean, default: true }
  },
  emits: ['searchSubmit'],
  setup(__props: any, { emit: __emit }) {


const props = __props;

const emit = __emit;

const store = useStore();

const isDesktop = computed((): boolean => store.state.config.isDesktop);
const formData = computed((): SearchFormDataType => store.state.searchMask);
const isHotelOnly = computed((): boolean => formData.value.onlyHotel);

const pageType = computed((): string => store.state.config.pageType);
const shouldShowSubmitButton = computed((): boolean => {
	if(['hotelPage', 'hotelList', 'regionList'].includes(pageType.value)) {
		return false;
	}
	return props.showSubmitButton;
});

const maxTravelDurationError = computed(() => store.state.searchMask.maxTravelDurationError);

const client = computed(() => store.state.config.client);

const blockClassModifiers = computed(() => ({
	[`search-bar-core--${props.variant}`]: Boolean(props.variant),
}));

const scrollUpSearchForm = (): void => {
	const searchForm: HTMLElement | null = document.querySelector('#search-form-container .search-form');
	if (!searchForm) {
		return;
	}

	const spaceSearchToBottom = (window.innerHeight - searchForm?.getBoundingClientRect().bottom);

	if (spaceSearchToBottom < 450) {
		scrollIt(
			searchForm,
			300,
			'easeOutQuad',
		);
	}
};

const onSubmit = (): void => {
	emit('searchSubmit', formData.value);
};

onMounted(() => {
	EventBus.$on('OfferSearch:ScrollUpSearchForm', scrollUpSearchForm);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['search-bar-core', blockClassModifiers.value])
  }, [
    _createElementVNode("form", {
      class: "search-bar-core__form",
      autocomplete: "false",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(TravelTypeSwitch, { class: "search-bar-core__switch" }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(DestinationField, { class: "search-bar-core__destination-field" }),
        _createVNode(ExpandTransition, {
          direction: isDesktop.value ? 'width' : 'height'
        }, {
          default: _withCtx(() => [
            (!isHotelOnly.value)
              ? (_openBlock(), _createBlock(AirportFilter, {
                  key: 0,
                  class: "search-bar-core__airport-filter",
                  client: client.value
                }, null, 8 /* PROPS */, ["client"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["direction"]),
        _createVNode(OfferDurationField, { class: "search-bar-core__offer-duration-field" }),
        _createVNode(TravelDurationField, { class: "search-bar-core__travel-duration-field" }),
        _createVNode(TravelersField, { class: "search-bar-core__passengers-field" }),
        (shouldShowSubmitButton.value)
          ? (_openBlock(), _createBlock(RoundedButton, {
              key: 0,
              type: "submit",
              theme: "extra",
              class: "search-bar-core__submit-button",
              disabled: maxTravelDurationError.value,
              onKeydown: [
                _withKeys(_withModifiers(onSubmit, ["prevent"]), ["enter"]),
                _withKeys(_withModifiers(onSubmit, ["prevent"]), ["space"])
              ]
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", { class: "search-bar-core__submit-button-text" }, _toDisplayString(submitButtonText))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onKeydown"]))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */)
  ], 2 /* CLASS */))
}
}

})