<template>
	<FilterType
		icon="bed"
		title="Zimmertyp"
		class="room-type-filter"
		aria-label="Zimmertyp Icon"
	>
		<ShowMoreContainer :item-count="roomTypes.length">
			<template #visibleContent>
				<li
					v-for="roomType in visibleRoomTypes"
					:key="roomType.id"
					class="room-type-filter__list-item"
				>
					<Checkbox
						v-model="selectedFilters"
						:custom-value="roomType.value"
						:label="roomType.label"
						:disabled="isCheckboxDisabled(roomType.value)"
					></Checkbox>
					<template v-if="isHotelPage">
						<FilterPrice
							:is-loading="isFetchLoading"
							:value="priceMap.get(roomType.value)"
						></FilterPrice>
					</template>
				</li>
			</template>
			<template #hiddenContent>
				<li
					v-for="roomType in hiddenRoomTypes"
					:key="roomType.id"
					class="room-type-filter__list-item"
				>
					<Checkbox
						v-model="selectedFilters"
						:custom-value="roomType.value"
						:label="roomType.label"
						:disabled="isCheckboxDisabled(roomType.value)"
					></Checkbox>
					<template v-if="isHotelPage">
						<FilterPrice
							:is-loading="isFetchLoading"
							:value="priceMap.get(roomType.value)"
						></FilterPrice>
					</template>
				</li>
			</template>
		</ShowMoreContainer>
	</FilterType>
</template>
<script lang="ts" setup>
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import ShowMoreContainer from '@lmt-rpb/ShowMoreContainer/ShowMoreContainer.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import roomTypes, { type RoomTypeValue } from '@/js/data/room-types';
import { computed } from 'vue';
import { useStore } from '@/components/common/store';
import FilterPrice from '@lmt-rpb/FilterPrice/FilterPrice.vue';

type Props = {
	isHotelPage?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	isHotelPage: false
});
const store = useStore();

const sortedRoomTypes = computed(() => {
	// only sort on hotelpage
	if (!props.isHotelPage) {
		return roomTypes
	}
	// don't sort if nothing is active
	if (activeFilters.value.size === 0) {
		return roomTypes
	}
	return roomTypes.toSorted((a, b) => {
		// sort first based on availibility
		if (activeFilters.value.has(a.value)) {
			if (activeFilters.value.has(b.value)) {
				return 0
			} else {
				return -1
			}
		}
		if (activeFilters.value.has(b.value)) {
			return 1
		}
		// if neither is active then sort the selected ones before the others
		if (selectedFilters.value.includes(a.value)) {
			if (selectedFilters.value.includes(b.value)) {
				return 0
			}
			return -1
		}
		return 1
	});
})

const visibleRoomTypes = computed(() => sortedRoomTypes.value.slice(0, 5));
const hiddenRoomTypes = computed(() => sortedRoomTypes.value.slice(5));

const selectedFilters = computed({
	get() {
		return store.state.searchMask.roomTypes;
	},
	set(value) {
		store.commit('searchMask/updateFormData', { roomTypes: value });
	}
});

const priceMap = computed(() => store.state.searchMask.room.priceMap);
const activeFilters = computed(() => store.state.searchMask.room.enabledFilters);
const isFetchLoading = computed(() => store.state.searchMask.room.isFetchLoading);

const isCheckboxDisabled = function(checkboxValue: RoomTypeValue) {
	if (!props.isHotelPage) {
		return false;
	}
	if (isFetchLoading.value) {
		return true
	}
	return !activeFilters.value.has(checkboxValue);
};

</script>

<style lang="scss" scoped>
.room-type-filter {
	&__list-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		:deep(.checkbox .checkbox__label) {
			font-weight: $font-weight-regular;
			font-size: $font-small-1;
		}

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}

		& > :deep(.checkbox.checkbox--disabled .checkbox__icon) {
			fill: $color-inactive;
			cursor: not-allowed;
		}
	}

	&__price {
		display: inline-block;
		margin-left: auto;
		color: $color-extra;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		margin-bottom: 0;
	}

	&__price-loader {
		display: inline-block;
		margin-left: auto;
		margin-bottom: 0;
	}
}
</style>
