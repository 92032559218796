import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import FilterHeader from '@lmt-rpb/FilterHeader/FilterHeader.vue';

type Props = {
	title?: string
	withDivider?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterContainer',
  props: {
    title: { default: undefined },
    withDivider: { type: Boolean, default: true }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["filter-container", { 'filter-container--with-divider': _ctx.withDivider }])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(FilterHeader, {
          key: 0,
          title: _ctx.title,
          class: "filter-container__header"
        }, null, 8 /* PROPS */, ["title"]))
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}
}

})