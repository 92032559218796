<template>
	<button
		type="button"
		aria-label="Close"
		:class="['close-button', blockClassModifiers]"
	>
		<BaseIcon
			class="close-button__icon"
			name="close"
			aria-hidden="true"
			focusable="false"
		/>
	</button>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed } from 'vue';

interface Props {
	inheritStyles?: boolean;
}

const { inheritStyles = false } = defineProps<Props>();
const blockClassModifiers = computed(() => ({
	'close-button--inherit-styles': inheritStyles,
}));

</script>

<style lang="scss" scoped>
.close-button {
	padding: 0;
	height: 1.4rem;
	aspect-ratio: 1/1;
	border: none;
	background: none;
	fill: $color-white;
	stroke: $color-white;
	color: $color-white;

	&:focus-within,
	&:focus {
		outline-color: $color-white;
	}

	&__icon {
		aspect-ratio: 1/1;
	}

	&--inherit-styles {
		fill: currentcolor;
		stroke: currentcolor;
		color: currentcolor;

		&:focus-within,
		&:focus {
			outline-color: currentcolor;
		}
	}
}
</style>
