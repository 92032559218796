import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "filter-header" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = {
  key: 1,
  class: "filter-header__title"
}

interface Props {
	title: string,
	icon?: string,
	iconLabel?: string,
	isCustomIcon?: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FilterHeader',
  props: {
    title: { default: '' },
    icon: { default: '' },
    iconLabel: { default: '' },
    isCustomIcon: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          "aria-label": _ctx.iconLabel,
          class: _normalizeClass([ _ctx.isCustomIcon ? 'fa-kit' : 'fa-solid',
			`fa-${_ctx.icon}`,
			'filter-header__icon'])
        }, null, 10 /* CLASS, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})