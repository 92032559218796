import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "travel-duration-content__header" }
const _hoisted_2 = { class: "travel-duration-content__body" }
const _hoisted_3 = { class: "travel-duration-content__box" }
const _hoisted_4 = { class: "travel-duration-content__items" }
const _hoisted_5 = { class: "travel-duration-content__list" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "travel-duration-content__warning"
}

import {
	computed, ref,
} from 'vue';
import RowList from '@lmt-rpb/RowList/RowList.vue';
import InnerDropdown from '@lmt-rpb/InnerDropdown/InnerDropdown.vue';
import { TRAVEL_DURATION_EXACT } from '@global-js/constants';
import { useStore } from '@/components/common/store';
import { travelDurationOptions, travelDurationSingleValues } from './travelDurationOptions';
import ChipBase from '@lmt-rpb/Atoms/ChipBase/ChipBase.vue';

interface Props {
	modelValue: {exact: boolean, duration: number[]},
	offerDurationDiff: number,
	errorMessage?: string,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TravelDurationContent',
  props: {
    modelValue: {},
    offerDurationDiff: {},
    errorMessage: { default: '' }
  },
  emits: ['update:modelValue', 'TravelDurationContent:IsExactSelected', 'TravelDurationContent:outOfRange', 'TravelDurationContent:TagSelected', 'TravelDurationContent:PillChanged'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const store = useStore();

const props = __props;

const emit = __emit;

const innerDropdown = ref<InstanceType<typeof InnerDropdown>>();

const activeElement = ref<null | number>(null);

const isShowDropdown = ref(
	!props.modelValue.exact
	&& props.modelValue.duration.length === 1
	&& !travelDurationSingleValues.find((o) => o === props.modelValue.duration[0])
);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const pillModel = computed({
	get() {
		const { exact, duration } = model.value;
		let result = 'exact';
		if (!exact && duration) {
			result = duration.join(',');
		}
		return result;
	},
	set(newValue) {
		isShowDropdown.value = false;
		model.value = {
			exact: !newValue || newValue === TRAVEL_DURATION_EXACT,
			duration: !newValue || newValue === TRAVEL_DURATION_EXACT ? [props.offerDurationDiff] : newValue.split(',').map(Number),
		};
		emit('TravelDurationContent:PillChanged', newValue, [props.offerDurationDiff]);
	}
});

const dropdownModel = computed({
	get() {
		const { exact, duration } = model.value;
		let result = '';
		if ((isShowDropdown.value || !travelDurationSingleValues.includes(duration[0])) && !exact && duration.length === 1) {
			result = duration[0].toString();
		}
		return result;
	},
	set(newValue) {
		isShowDropdown.value = true;
		model.value = {
			exact: false,
			duration: [Number(newValue)]
		};
	}
});

const clickOutside = () => {
	if (innerDropdown.value) {
		innerDropdown.value.open = false;
	}
};

function selectDropdownDay(day: string) {
	dropdownModel.value = day.toString();
	innerDropdown.value?.closeDropdown();
	emit('TravelDurationContent:PillChanged', false);
}

__expose({
	model
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(RowList, { class: "travel-duration-content" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(travelDurationOptions), (tag) => {
              return (_openBlock(), _createBlock(ChipBase, {
                key: tag.label,
                label: tag.label,
                name: "travel-duration",
                modelValue: pillModel.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((pillModel).value = $event)),
                value: tag.value,
                as: 'radio',
                class: "travel-duration-content__pill"
              }, null, 8 /* PROPS */, ["label", "modelValue", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "travel-duration-content__value" }, "genau", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(InnerDropdown, {
              ref_key: "innerDropdown",
              ref: innerDropdown,
              placeholder: '',
              "show-footer": false,
              "model-value": dropdownModel.value,
              "show-toggle-icon": true,
              "onDropdownField:OutsideClick": clickOutside
            }, {
              default: _withCtx(() => [
                _createElementVNode("ul", _hoisted_5, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(28, (day, index) => {
                    return _createElementVNode("li", {
                      key: `${day}-${index}`,
                      role: "button",
                      class: _normalizeClass(["travel-duration__content", {'travel-duration__content--active' : activeElement.value === index }]),
                      onClick: ($event: any) => (selectDropdownDay(day.toString()))
                    }, _toDisplayString(day), 11 /* TEXT, CLASS, PROPS */, _hoisted_6)
                  }), 64 /* STABLE_FRAGMENT */))
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["model-value"])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "travel-duration-content__value" }, "Tage", -1 /* HOISTED */))
        ])
      ]),
      _: 1 /* STABLE */
    }),
    (model.value.duration[0] > _ctx.offerDurationDiff || _ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(model.value.duration[0] > _ctx.offerDurationDiff ?
					'Die gewählte Dauer ist zu lang für den eingestellten Zeitraum. Möchten Sie die Reisedaten ändern?' :
					_ctx.errorMessage), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})