import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-form__search" }

import Loading from '@lmt-rpb/Loading/Loading.vue';
import { SearchFormDataType, BackgroundImages } from '@interfaces/search-form';
import { objectToQuery, formToParams } from '@services/transform';
import { EventBus } from '@global-js/event-bus';
import { isOfferlistPage, showOfferlist } from '@utils/utils';
import { getBasePath } from '@utils/environmentUtils';
import {
	computed, onBeforeMount, onBeforeUnmount, onMounted, ref,
} from 'vue';
import { useStore } from '@/components/common/store';
import * as searchHistoryService from '@/components/common/services/localStorage/searchHistoryService';
import { ClientType } from '@/interfaces/common';
import ModalManager from '@/js/modules/modalManager';
import SearchBarCore from '@lmt-rpb/Organisms/SearchBarCore/SearchBarCore.vue';

interface Props {
	client?: ClientType,
	images?: BackgroundImages | undefined,
	searchBarVariant?: 'standalone' | 'inline',
	showSubmitButton?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchForm',
  props: {
    client: { default: 'at' },
    images: { default: undefined },
    searchBarVariant: { default: 'standalone' },
    showSubmitButton: { type: Boolean, default: true }
  },
  setup(__props: any, { expose: __expose }) {

const props = __props;

const store = useStore();

const showBackground = ref(false);
const redirecting = ref(false);

const isMobile = computed((): boolean => store.state.config.isMobile);

const baseUrl = computed((): string => store.state.config.baseUrl);

const pageType = computed((): string => store.state.config.pageType);

const formData = computed((): SearchFormDataType => store.state.searchMask);

const locationType = computed((): string => store.state.config.locationType);

const isDesktop = computed(() => store.state.config.isDesktop);

const targetUrl = computed((): string => {
	let url = store.state.config.searchUrl; // default url
	if (formData.value.destination && formData.value.destination.url) {
		url = `${baseUrl.value}${formData.value.destination.url}`;
	} else {
		url = `${baseUrl.value}/region/`;
	}

	return url;
});

const basePath = getBasePath(props.client) + '/img/search-form';

const getBackgroundStyleProp = (images: BackgroundImages, backgroundSettings: string): Record<string, string> => {
	const { mobile, tablet, desktop } = images;

	if (isDesktop.value) {
		return { background: `url('${desktop}') ${backgroundSettings}` };
	}
	if (isMobile.value) {
		return { background: `url('${mobile}') ${backgroundSettings}` };
	}
	return { background: `url('${tablet}') ${backgroundSettings}` };
};

const handleFallbackImages = (backgroundSettings: string): Record<string, string> => {
	const images = {
		desktop: `${basePath.value}/main-image-desktop.jpg`,
		tablet: `${basePath.value}/main-image-tablet.jpg`,
		mobile: `${basePath.value}/main-image-mobile.jpg`,
	};

	return getBackgroundStyleProp(images, backgroundSettings);
};

const bgImages = computed((): Record<string, string> => {
	const backgroundPositionX = isMobile.value ? '20%' : 'center';
	const backgroundSettings = `${backgroundPositionX} 70%/cover no-repeat`;

	if (!props.images) return handleFallbackImages(backgroundSettings);
	const { mobile, tablet, desktop } = props.images;

	// In case bg images are missing after edev cloning
	// TODO: remove after edevs are gone
	if (!desktop || !tablet || !mobile) return handleFallbackImages(backgroundSettings);

	return getBackgroundStyleProp(props.images, backgroundSettings);
});

const selectedDestinationChanged = computed((): boolean => JSON.stringify(formData.value.destination) !== JSON.stringify(store.state.proxies?.initialDestination));

const onSubmit = (noLoadingSpinner: boolean = false): void => {
	searchHistoryService.update();
	const body = store.getters['searchMask/submitData'];
	const params = formToParams(body, 'search');

	if (locationType.value === 'COUNTRY' && params.rid) {
		delete params.rid;
	}
	if (locationType.value === 'TOPREGION' && params.cyid) {
		delete params.cyid;
	}

	const query = objectToQuery(params);

	const searchTerm = formData.value.searchTerm;
	const travelType = formData.value.onlyHotel ? 'hotel' : 'package';
	let url = '';

	url = `${targetUrl.value}?${query}`
			+ (searchTerm
				? `&term=${encodeURIComponent(searchTerm)}`
				: '');

	// When redirecting to our own hotel pages, add the ibe parameter
	if (url.indexOf('ibe=') === -1
				&& url.indexOf('/hotel/') !== -1) {
		url += (url.indexOf('?') !== -1) ? '&' : '?';
		url += 'ibe=' + travelType;
	}

	if (!store.state.config.redirectOnSubmit) {
		console.log('redirect: ', url);
	} else if (noLoadingSpinner) {
		redirectTo(url);
	} else {
		document.body.style.opacity = '0.3';
		redirecting.value = true;
		redirectTo(url);
	}
};

function redirectTo(url: string) {
	window.location.href = url;
	// necessary so redirect works on chrome modal
	ModalManager.redirectTo = url;
}

const handlePopState = ({ state }: PopStateEvent): void => {
	EventBus.$emit('window:popstate', state);
};

const handleKeydown = ({ key }: KeyboardEvent): void => {
	if (!key) {
		return;
	}

	const keyName = key.toLowerCase();

	if (['enter', 'escape'].includes(keyName)) {
		EventBus.$emit(`keydown.${keyName}`);
	}
};

const handlePageRedirect = (): void => {
	redirecting.value = false;
	document.body.style.opacity = '1';
};

const dynamicSubmit = (): void => {
	if (isOfferlistPage() && !selectedDestinationChanged.value) {
		EventBus.$emit('SearchMask:MobileSubmit');

		if (pageType.value === 'hotelPage') {
			if (isDesktop.value) {
				showOfferlist('#offerlist', 50, 1000);
				setTimeout(() => {
					document.body.classList.add('page-header--hidden');
				}, 2000);
			} else {
				showOfferlist('.offers__list', 200, 1000);
				setTimeout(() => {
					document.body.classList.add('page-header--hidden');
				}, 200);
			}
		} else if (pageType.value === 'hotelList') {
			showOfferlist('.hotel-list', 150, 1000);
		} else if (pageType.value === 'regionList') {
			showOfferlist('.region-list', 150, 1000);
		}
	} else {
		onSubmit();
	}
};

onBeforeMount(() => {
	window.addEventListener('popstate', handlePopState);
	document.addEventListener('keydown', handleKeydown);

	// Prevents endless loading spinner if user clicks back fast from ibe on >= iOS 13
	window.addEventListener('pageshow', handlePageRedirect);

	EventBus.$on('search:submit', (buttonSearch = false) => {
		// redirectFired boolean to hide the second loader
		if (!store.state.searchMask.redirectFired || buttonSearch) {
			onSubmit(buttonSearch);
		}
		if (!buttonSearch) {
			// dont set this value when buttonSearch. When Browser back it wouldn't fire anymore.
			store.state.searchMask.redirectFired = true;
		}
	});
});

onMounted(() => {
	if (pageType.value === 'homePage') {
		showBackground.value = true;
	}
	store.dispatch('updateProxies', { initialDestination: formData.value.destination });
});

onBeforeUnmount((): void => {
	window.removeEventListener('popstate', handlePopState);
	document.removeEventListener('keydown', handleKeydown);
	window.removeEventListener('pageshow', handlePageRedirect);
	EventBus.$off('search:submit', onSubmit);
});

__expose({
	dynamicSubmit,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["search-form", {
			'show-background': showBackground.value,
		}]),
    style: _normalizeStyle([showBackground.value ? bgImages.value : {backgroundImage: 'none'}])
  }, [
    (redirecting.value)
      ? (_openBlock(), _createBlock(Loading, {
          key: 0,
          class: "search-form__loading"
        }))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SearchBarCore, {
        ref: "main",
        class: "search-form__search-bar",
        variant: _ctx.searchBarVariant,
        onSearchSubmit: dynamicSubmit,
        "show-submit-button": _ctx.showSubmitButton
      }, null, 8 /* PROPS */, ["variant", "show-submit-button"])
    ])
  ], 6 /* CLASS, STYLE */))
}
}

})