<template>
	<div
		tabindex="0"
		class="bookmark-hotel-button"
		:class="{'bookmark-hotel-button--active': bookmarked}"
		:data-iff="item.Hotel.IffCode"
		:data-ga-name="item.Hotel.Name"
		:data-ga-category="item.Location.Country.CountryName + ' / ' + item.Location.Region.RegionName + ' / ' + item.Location.City.CityName"
		@click.prevent.stop="toggleBookmark()"
		@mousedown.prevent.stop
	>
		<BaseIcon
			class="bookmark-hotel-button__heart-icon"
			name="heart"
			viewBox="-2 -2 32 32"
		/>
	</div>
</template>

<script lang="ts" setup>
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import BookmarkAnalytics from '@/js/modules/analytics/BookmarkAnalytics';
import { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import {
	computed, onBeforeUnmount, onBeforeMount, ref,
} from 'vue';
import {
	addEntryToFavoriteCookie,
	removeEntryFromFavoriteCookie,
	isFavorite as isFavoriteCookie,
} from '@modules/bookmark';
import { EventBus } from '@global-js/event-bus';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	item: ItemType;
}

const props = withDefaults(defineProps<Props>(), {
});

const offer = computed(() => props.item.PackageOffer || props.item.AccommodationOffer!);

const bookmarked = ref(false);

onBeforeMount(() => {
	EventBus.$on('bookmark:favoriteChange', updateBookmark);
	EventBus.$on('bookmark:hotelsUpdated', (hotels: FavoritedHotel[]) => {
		bookmarked.value = hotels.some((h) => h.IffCode === props.item.Hotel.IffCode);
	});
	checkForFavorites();
});

onBeforeUnmount((): void => {
	EventBus.$off('bookmark:favoriteChange', updateBookmark);
	EventBus.$off('bookmark:hotelsUpdated');
});

function toggleBookmark() {
	if (props.item) {
		const item = BookmarkAnalytics.fromItemType(props.item.Hotel, props.item.Location, offer.value);
		if (!bookmarked.value) {
			addEntryToFavoriteCookie(item, props.item.Hotel.Category);
		} else {
			removeEntryFromFavoriteCookie(item);
		}
	}
}

function updateBookmark(iffCode: number, favorite: boolean) {
	if (iffCode === props.item.Hotel.IffCode) {
		bookmarked.value = favorite;
	}
}

async function checkForFavorites() {
	if (props.item?.Hotel.IffCode) {
		bookmarked.value = await isFavoriteCookie(props.item.Hotel.IffCode);
	}
}
</script>
<style lang="scss" scoped>
.bookmark-hotel-button {
	padding: 0.3rem 0;

	&__svg {
		fill: #A2A2A2;
		stroke-width: 0.1rem;
		width: 2.1rem;
		transition: fill 0.3s, stroke 0.3s, transform 0.3s;
		display: block;
	}

	&:focus {
		outline: 0;

		svg {
			transform: scale(1.1);
			fill: $color-white-t5;
		}
	}

	&--active {
		svg { /* stylelint-disable-line no-descending-specificity */
			fill: $color-extra;
			animation: heartbeat 0.25s 2;
		}

		&:hover svg {
			transform: scale(1);
		}

		&:focus svg {
			fill: $color-extra;
		}
	}

	&:hover svg {
		transform: scale(1.1);
	}
}

@keyframes heartbeat {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}
</style>
