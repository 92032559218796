<template>
	<FilterType 
		icon="bus" 
		title="Hoteltransfer" 
		class="hotel-transfer-filter"
		aria-label="Auto Icon"
	>
		<FilterRadioButtonList id="hotel-transfer" v-model="selectedTransfer" :items="[...transferTypes]"
			class="hotel-transfer-filter__list" />
	</FilterType>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import FilterRadioButtonList from '@lmt-rpb/FilterRadioButtonList/FilterRadioButtonList.vue';
import { ANY } from '@global-js/constants';
import { transferTypesNew as transferTypes } from '@/js/data/transfer-types';
import { useStore } from '@/components/common/store';

const store = useStore();

const selectedTransfer = computed({
	get: () => {
		const transferType = store.state.searchMask.transferTypes;
		return transferType.length === 0 ? ANY : transferType[0];
	},
	set: (newValue) => {
		const valueToCommit = newValue === ANY ? [] : [newValue];
		store.commit('searchMask/updateFormData', { transferTypes: valueToCommit });
	},
});

</script>

<style lang="scss" scoped>
.hotel-transfer-filter {
	&__list {
		margin-left: 0.8rem;
	}
}
</style>
