import type { InsuranceOffer } from '@/interfaces/checkout/insurance-data';
import type { ItemType, ItemTypeWithAccommodationOffer, ItemTypeWithPackageOffer } from '@/interfaces/hotel-list-types/hotel-list-types';

/**
 * Type guard also known as a runtime check or type predicate https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
*/
export function isBestAccommodationOfferForHotelList(offer: ItemType): offer is ItemTypeWithAccommodationOffer {
	return 'AccommodationOffer' in offer;
}

/**
 * Type guard also known as a runtime check or type predicate https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
*/
export function isBestPackageOfferForHotelList(offer: ItemType): offer is ItemTypeWithPackageOffer {
	return 'PackageOffer' in offer;
}

export function isInsuranceOffer(insuranceOffer: InsuranceOffer | boolean): insuranceOffer is InsuranceOffer {
	return typeof insuranceOffer !== 'boolean';
}

/**
 * A Utility type that makes all properties (nested as well) of an object optional
 */
export type DeepPartial<T> = T extends object ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;
