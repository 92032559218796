import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "board-type-filter__list" }
const _hoisted_2 = { class: "board-type-filter__list-item" }

import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import FilterRadioButton from '@lmt-rpb/FilterRadioButton/FilterRadioButton.vue';
import { boardTypes } from '@/js/data';
import { computed } from 'vue';
import { useStore } from '@/components/common/store';
import type { BoardTypeValue } from '@/js/data/board-types';
import { ANY } from '@global-js/constants';
import FilterPrice from '@lmt-rpb/FilterPrice/FilterPrice.vue';

type Props = {
	isHotelPage?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BoardTypeFilter',
  props: {
    isHotelPage: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const actualBoardTypes = computed(
	() => boardTypes
		.filter((board) => board.showInMenu)
);

const store = useStore();

const selectedFilter = computed({
	get() {
		return store.state.searchMask.boardTypes[0] ?? ANY;
	},
	set(value) {
		store.commit('searchMask/updateFormData', { boardTypes: value === ANY ? [] : [value] });
	}
});

const priceMap = computed(() => {
	return store.state.searchMask.board.priceMap
});

const activeFilters = computed(() => store.state.searchMask.board.enabledFilters);
const isFetchLoading = computed(() => store.state.searchMask.board.isFetchLoading);

const isFilterDisabled = function(filterValue: BoardTypeValue | typeof ANY) {
	if (!props.isHotelPage) {
		return false;
	}
	if (isFetchLoading.value) {
		return true
	}
	if (filterValue === ANY) {
		return activeFilters.value.size === 0;
	}
	return !activeFilters.value.has(filterValue);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FilterType, {
    class: "board-type-filter",
    icon: "utensils",
    title: "Verpflegung",
    "aria-label": "Verpflegung Icon"
  }, {
    default: _withCtx(() => [
      _createElementVNode("menu", _hoisted_1, [
        _createElementVNode("li", _hoisted_2, [
          (_openBlock(), _createBlock(FilterRadioButton, {
            id: "board-type",
            key: 0,
            modelValue: selectedFilter.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedFilter).value = $event)),
            label: "Beliebig",
            value: "Any",
            disabled: isFilterDisabled(_unref(ANY))
          }, null, 8 /* PROPS */, ["modelValue", "disabled"]))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actualBoardTypes.value, (board) => {
          return (_openBlock(), _createElementBlock("li", {
            key: board.id,
            class: "board-type-filter__list-item"
          }, [
            _createVNode(FilterRadioButton, {
              id: "board-type",
              modelValue: selectedFilter.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedFilter).value = $event)),
              label: board.upsellingLabel,
              value: board.value,
              disabled: isFilterDisabled(board.value)
            }, null, 8 /* PROPS */, ["modelValue", "label", "value", "disabled"]),
            (_ctx.isHotelPage)
              ? (_openBlock(), _createBlock(FilterPrice, {
                  key: 0,
                  "is-loading": isFetchLoading.value,
                  value: priceMap.value.get(board.value)
                }, null, 8 /* PROPS */, ["is-loading", "value"]))
              : _createCommentVNode("v-if", true)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})