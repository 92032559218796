import {
 computed, ComputedRef, unref, MaybeRef,
} from 'vue';
import { getDepositAmount } from '@utils/checkoutUtils';
import { formatDate, dateDiff } from '@utils/utils';
import { getFormatPriceEur, getFormatPriceConverted } from '@utils/priceUtils';
import { isClientSwitzerland } from '@utils/environmentUtils';
import type { PriceInformation, SimplePrice } from '@/interfaces/checkout/offer-data';
import { getToday } from '@/js/utils/dateUtils';

// Constants
const PAYMENT_STRINGS = {
  IMMEDIATELY_DUE: 'sofort fällig.',
  DEPOSIT: 'Anzahlung',
  FINAL_PAYMENT: 'Restzahlung',
  PAYMENT: 'Zahlung',
  DEFAULT_FALLBACK: 'Die Höhe und Fälligkeit von An- und Restzahlung finden Sie unten in den Allgemeinen Geschäftsbedingungen Ihres Reiseveranstalters unter den Zahlungsbedingungen.'
} as const;

interface PaymentPriceInfo extends SimplePrice {
  DueDate?: string;
}

interface PaymentInfo {
  depositAmount: ComputedRef<string | undefined>;
  finalPaymentConverted: ComputedRef<string | undefined>;
  shouldShowFallback: ComputedRef<boolean>;
  formattedDepositText: ComputedRef<string>;
  formattedFinalPaymentText: ComputedRef<string>;
  totalPaymentString: ComputedRef<string>;
}

function formatPaymentAmount(price: PaymentPriceInfo): { eur: string; chf?: string } {
  const eurAmount = getFormatPriceEur(price, true);
  const chfAmount = price.Converted
    ? getFormatPriceConverted(price.Converted, true)
    : undefined;

  return { eur: eurAmount, chf: chfAmount };
}

function formatDueDate(dueDate?: string): string {
  if (!dueDate || dueDate === getToday()) {
    return PAYMENT_STRINGS.IMMEDIATELY_DUE;
  }
  return `bis ${formatDate(dueDate, { year: 'numeric', month: '2-digit', day: '2-digit' })}.`;
}

function isAmountEqual(firstAmount: number, secondAmount: number): boolean {
  const difference = Math.abs(Number(firstAmount) - Number(secondAmount));
  return difference <= 1;
}

// Main logic to check if fallback should be shown
function shouldShowFallbackPaymentInfo(
  priceInformation?: PriceInformation,
  startDate?: string
): boolean {
  if (!priceInformation) return true;

  const finalPayment = priceInformation.FinalPayment;
  const total = priceInformation.Total;
  const deposit = priceInformation.Deposit;

  // If no final payment or no total, show fallback
  if (!finalPayment?.Amount || !total?.Amount) return true;

  // If there's a deposit, don't show fallback
  if (deposit?.Amount && finalPayment.Amount) return false;

  // Check if amounts are equal (within 1 euro difference) and there's a due date
  const amountsMatch = isAmountEqual(finalPayment.Amount, total.Amount);

  // If amounts don't match (more than 1 euro difference), show fallback
  if (!amountsMatch) return true;

  // If no due date, check start date
  if (!finalPayment.DueDate) {
    if (startDate) {
      const daysUntilStart = dateDiff(new Date(), startDate);
      // if start date is less than 31 days away, show 'soft fällig' otherwise show fallback
      return daysUntilStart > 31;
    }
    return true;
  }

  return false;
}

function formatAmountWithCurrency(amount: PaymentPriceInfo, prefix: string, dueText: string): string {
  const { eur, chf } = formatPaymentAmount(amount);
  if (!chf) {
    return `<strong>${prefix}: ${eur}</strong> ${dueText}`;
  }
  return `<strong>${prefix}: ${chf}</strong> (${eur}) ${dueText}`;
}

function getPaymentText(priceInformation: PriceInformation, finalPayment: PaymentPriceInfo): string {
  const total = priceInformation.Total;
  const shouldUseTotalAmount = total?.Amount && isAmountEqual(finalPayment.Amount, total.Amount);
  const amountToUse = shouldUseTotalAmount ? total : finalPayment;
  const dueText = formatDueDate(finalPayment.DueDate);
  const prefix = !priceInformation.Deposit?.Amount ? PAYMENT_STRINGS.PAYMENT : PAYMENT_STRINGS.FINAL_PAYMENT;
  return formatAmountWithCurrency(amountToUse, prefix, dueText);
}

export function usePaymentInfo(
  priceInformation: MaybeRef<PriceInformation | undefined>,
  flexStorno?: MaybeRef<SimplePrice | undefined>,
  startDate?: MaybeRef<string | undefined>,
  customFallbackText?: MaybeRef<string | undefined>
): PaymentInfo {
  const fallbackText = computed(() => unref(customFallbackText) || PAYMENT_STRINGS.DEFAULT_FALLBACK);

  const depositAmount = computed(() => {
    const amount = getDepositAmount(unref(priceInformation), unref(flexStorno));
    return amount
      ? getFormatPriceEur({ Amount: Number(amount), Currency: 'EUR' }, true)
      : undefined;
  });

  const finalPaymentConverted = computed(() => {
    const priceInfo = unref(priceInformation);
    if (!priceInfo?.FinalPayment?.Converted) return undefined;
    return getFormatPriceConverted(priceInfo.FinalPayment.Converted, true);
  });

  const shouldShowFallback = computed(() => shouldShowFallbackPaymentInfo(unref(priceInformation), unref(startDate)));

  const formattedDepositText = computed((): string => {
    const priceInfo = unref(priceInformation);
    if (!priceInfo?.Deposit?.Amount) return '';

    const dueText = formatDueDate(priceInfo.Deposit.DueDate);
    return formatAmountWithCurrency(priceInfo.Deposit, PAYMENT_STRINGS.DEPOSIT, dueText);
  });

  const formattedFinalPaymentText = computed((): string => {
    const priceInfo = unref(priceInformation);

    if (shouldShowFallback.value) {
      const isSwissClient = isClientSwitzerland();
      return isSwissClient
        ? `${fallbackText.value}<br>Der Vertrag wird in EURO geschlossen. Die Angabe in CHF dient lediglich Ihrer Information und ist ein Circa-Wert.`
        : fallbackText.value;
    }

    if (!priceInfo?.FinalPayment?.Amount) return '';

    return getPaymentText(priceInfo, priceInfo.FinalPayment);
  });

  const totalPaymentString = computed((): string => {
    const priceInfo = unref(priceInformation);
    if (!priceInfo?.Total) return '';

    const { eur, chf } = formatPaymentAmount(priceInfo.Total);
    return !chf ? `<strong>${eur}</strong>` : `<strong>${chf}</strong> (${eur})`;
  });

  return {
    depositAmount,
    finalPaymentConverted,
    shouldShowFallback,
    formattedDepositText,
    formattedFinalPaymentText,
    totalPaymentString
  };
}
