import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import type { ButtonType } from '@helper/types/commonProps';
import type  { RoundedButtonTheme } from './defaults/theme';

interface Props {
	as?: 'a' | 'button';
	theme?: RoundedButtonTheme;
	type?: ButtonType;
	disabled?: boolean;
	titleValue?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RoundedButton',
  props: {
    as: { default: 'button' },
    theme: {},
    type: { default: 'button' },
    disabled: { type: Boolean, default: false },
    titleValue: { default: '' }
  },
  setup(__props: any) {



const classes = computed(() => ({
	[`button--${__props.theme}`]: Boolean(__props.theme),
	'is-disabled': __props.disabled
}));

const defaults = computed(() => (__props.as === 'button' ? { type: __props.type } : {}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), _mergeProps({
    class: ["button", classes.value],
    disabled: _ctx.disabled,
    title: _ctx.titleValue
  }, {...defaults.value, ..._ctx.$attrs}), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["class", "disabled", "title"]))
}
}

})