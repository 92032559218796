import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed } from 'vue';

interface Props {
	inheritStyles?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CloseButton',
  props: {
    inheritStyles: { type: Boolean, default: false }
  },
  setup(__props: any) {


const blockClassModifiers = computed(() => ({
	'close-button--inherit-styles': __props.inheritStyles,
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    "aria-label": "Close",
    class: _normalizeClass(['close-button', blockClassModifiers.value])
  }, [
    _createVNode(BaseIcon, {
      class: "close-button__icon",
      name: "close",
      "aria-hidden": "true",
      focusable: "false"
    })
  ], 2 /* CLASS */))
}
}

})