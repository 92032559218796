import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withKeys as _withKeys, Transition as _Transition, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "payment-method" }
const _hoisted_2 = { class: "payment-method__header" }
const _hoisted_3 = {
  key: 0,
  class: "payment-radio-group__error"
}
const _hoisted_4 = {
  key: 1,
  class: "payment-method__info-container"
}
const _hoisted_5 = {
  key: 0,
  class: "payment-radio-group__error"
}
const _hoisted_6 = {
  key: 1,
  class: "payment-method__credit-card-service-text"
}
const _hoisted_7 = { class: "payment-method__credit-card-info" }
const _hoisted_8 = {
  key: 0,
  class: "payment-method__info-bubble"
}
const _hoisted_9 = { class: "payment-method__info-container" }
const _hoisted_10 = { class: "payment-method__headline-add sepa" }
const _hoisted_11 = {
  key: 0,
  class: "payment-method__sepa-info"
}
const _hoisted_12 = {
  key: 2,
  class: "payment-method__headline-add"
}
const _hoisted_13 = {
  key: 3,
  class: "payment-method__payment-due-text"
}

import PaymentRadioButtonGroup from '@lmt-rpb/PaymentRadioButtonGroup/PaymentRadioButtonGroup.vue';
import CreditCardRadioButtonGroup from '@lmt-rpb/CreditCardRadioButtonGroup/CreditCardRadioButtonGroup.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import CheckoutSepaFormFields from '@lmt-rpb/CheckoutSepaFormFields/CheckoutSepaFormFields.vue';
import CheckoutPaymentMethodInfo from '@lmt-rpb/CheckoutPaymentMethodInfo/CheckoutPaymentMethodInfo.vue';
import CheckoutPaymentInfo from '@lmt-rpb/CheckoutPaymentInfo/CheckoutPaymentInfo.vue';
import { PAYMENT_TYPES, PAYMENT_TYPES_CONSTANTS } from '@global-js/constants';
import {
	computed,
	ref,
	watch,
} from 'vue';
import { PriceInformation, SimplePrice } from '@/interfaces/checkout/offer-data';
import type { CardPaymentProviders, PaymentOptions, PaymentTypesKey } from '@/interfaces/checkout/checkoutTypes';
import { useStore } from '@/components/common/store';
import type { RadioButtonCommonProps } from '@/interfaces/components/inputs';
import { CheckoutValidationState } from '@/components/common/store/checkout';

interface Props {
	paymentOptions: PaymentOptions,
	priceInfo: PriceInformation,
	flexStornoAmount?: SimplePrice,
	isPaymentPage?: boolean,
	startDate?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutPaymentMethod',
  props: {
    paymentOptions: {},
    priceInfo: {},
    flexStornoAmount: {},
    isPaymentPage: { type: Boolean, default: false },
    startDate: {}
  },
  emits: ['CheckoutPaymentMethod:PaymentInfoChanged'],
  setup(__props: any, { emit: __emit }) {

const store = useStore();
const props = __props;

const emit = __emit;

const paymentInfo = ref<{ selectedPayment: keyof PaymentOptions, selectedCreditCard: string}>({
	selectedPayment: '' as keyof PaymentOptions,
	selectedCreditCard: ''
});

const selectedPaymentOption = computed(() => props.paymentOptions?.[paymentInfo.value.selectedPayment]);

const priceInfoFromStore = computed((): PriceInformation => store.state.checkout?.OfferData?.Offer?.PriceInformation || props.priceInfo);

initCheckoutValidation();

function initCheckoutValidation() {
	CheckoutValidationState.value['payment-type'] = {
		valid: false, validated: false, hasBackendError: false
	};
	CheckoutValidationState.value['credit-card-radio-group'] = {
		valid: false, validated: false, hasBackendError: false
	};
}

const isMobile = computed(() => store.state.config.isMobile);

const paymentRadioValid = computed((): boolean => CheckoutValidationState.value['payment-type'].valid);
const paymentRadioValidated = computed((): boolean => CheckoutValidationState.value['payment-type'].validated);

const creditCardValid = computed((): boolean => CheckoutValidationState.value['credit-card-radio-group'].valid);
const creditCardValidated = computed((): boolean => CheckoutValidationState.value['credit-card-radio-group'].validated);

const showInfoText = ref(false);

const selectedPaymentIsSepa = computed(() => paymentInfo.value.selectedPayment === PAYMENT_TYPES_CONSTANTS.SEPA);

const creditCards = computed(() => {
	const cards: RadioButtonCommonProps<CardPaymentProviders>[] = [];
	Object.entries(props.paymentOptions).forEach(([key, value]) => {
		if (value.type === 'CreditCard') cards.push({ label: value.label, value: key as CardPaymentProviders });
	});
	return cards;
});

const overallPaymentMethods = computed(() => {
	const methods: RadioButtonCommonProps[] = [];
	Object.entries(props.paymentOptions).forEach(([key, value]) => {
		if (value.type !== 'CreditCard') {
			methods.push({ label: value.label, value: key });
		}
	});

	if (creditCards.value.length) {
		methods.push({ label: 'Kreditkarte', value: PAYMENT_TYPES_CONSTANTS.CREDIT_CARD });
	}
	return methods;
});

const creditCardServiceText = computed((): string => {
	let serviceText = '';
	Object.values(props.paymentOptions).forEach((value) => {
		if (
			value.label === paymentInfo.value.selectedCreditCard
				&& value.serviceText
		) {
			serviceText = value.serviceText;
		}
	});
	return serviceText;
});

const selectBankTransfer = () => {
	const paymentType: PaymentTypesKey = 'BankTransfer';
	store.dispatch('checkout/updateTravel', { PaymentType: paymentType });
	emit('CheckoutPaymentMethod:PaymentInfoChanged', paymentType);
};

const isCreditCardSelected = computed(() => paymentInfo.value.selectedPayment === PAYMENT_TYPES_CONSTANTS.CREDIT_CARD);

watch(() => paymentInfo.value, () => {
	const payment = paymentInfo.value;
	if (isCreditCardSelected.value) {
		let paymentMethodSelected: PaymentTypesKey | '' = '';
		(Object.keys(PAYMENT_TYPES) as Array<PaymentTypesKey>).forEach((paymentMethod) => {
			if (paymentMethod === payment.selectedCreditCard) {
				paymentMethodSelected = paymentMethod;
				CheckoutValidationState.value['credit-card-radio-group'].valid = true;
				CheckoutValidationState.value['credit-card-radio-group'].validated = true;
			}
		});
		CheckoutValidationState.value['payment-type'].valid = true;
		CheckoutValidationState.value['payment-type'].validated = true;
		emit('CheckoutPaymentMethod:PaymentInfoChanged', paymentMethodSelected);
		store.dispatch('checkout/updateTravel', { PaymentType: paymentMethodSelected });
		return;
	}
	if (payment.selectedPayment === 'BankTransfer') {
		selectBankTransfer();
	}
	if (selectedPaymentIsSepa.value) {
		const paymentType: PaymentTypesKey = 'DirectDebitInternational';
		emit('CheckoutPaymentMethod:PaymentInfoChanged', paymentType);
		store.dispatch('checkout/updateTravel', { PaymentType: paymentType });
	}

	CheckoutValidationState.value['payment-type'].valid = true;
	CheckoutValidationState.value['payment-type'].validated = true;
}, { deep: true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "payment-method__headline" }, " Bevorzugte Zahlungsart: ", -1 /* HOISTED */)),
      (!isMobile.value)
        ? (_openBlock(), _createBlock(CheckoutPaymentMethodInfo, { key: 0 }))
        : _createCommentVNode("v-if", true)
    ]),
    (!paymentRadioValid.value && paymentRadioValidated.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createVNode(BaseIcon, {
            name: "exclamationMarkFill",
            class: "payment-radio-group__exclamation"
          }),
          _cache[6] || (_cache[6] = _createTextVNode(" Zahlungsart auswählen "))
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(PaymentRadioButtonGroup, {
      id: "payment-type",
      modelValue: paymentInfo.value.selectedPayment,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((paymentInfo.value.selectedPayment) = $event)),
      class: _normalizeClass({ 'payment-radio-group__error':!paymentRadioValid.value && paymentRadioValidated.value }),
      options: overallPaymentMethods.value
    }, null, 8 /* PROPS */, ["modelValue", "class", "options"]),
    (isCreditCardSelected.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "payment-method__headline-add" }, " Kreditkartenanbieter ", -1 /* HOISTED */)),
          (!creditCardValid.value && creditCardValidated.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Kreditkarte auswählen "))
            : _createCommentVNode("v-if", true),
          _createVNode(CreditCardRadioButtonGroup, {
            id: "credit-card-radio-group",
            modelValue: paymentInfo.value.selectedCreditCard,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((paymentInfo.value.selectedCreditCard) = $event)),
            class: _normalizeClass({ 'payment-radio-group__error': !creditCardValid.value && creditCardValidated.value }),
            options: creditCards.value
          }, null, 8 /* PROPS */, ["modelValue", "class", "options"]),
          (creditCardServiceText.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(creditCardServiceText.value), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_7, [
            _cache[8] || (_cache[8] = _createElementVNode("p", { class: "payment-method__credit-card-info-text" }, [
              _createTextVNode(" Wählen Sie hier Ihre Kreditkarte, "),
              _createElementVNode("strong", null, " die Eingabe Ihrer Kreditkartendaten erfolgt nach Abschluss der Buchung. ")
            ], -1 /* HOISTED */)),
            _createVNode(BaseIcon, {
              class: "payment-method__question-icon",
              name: "questionCircle",
              onKeypress: _cache[2] || (_cache[2] = _withKeys(($event: any) => (showInfoText.value = !showInfoText.value), ["enter","native"])),
              onMouseover: _cache[3] || (_cache[3] = ($event: any) => (showInfoText.value = true)),
              onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (showInfoText.value = false))
            }),
            _createVNode(_Transition, { name: "info-text" }, {
              default: _withCtx(() => [
                (showInfoText.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[7] || (_cache[7] = [
                      _createElementVNode("span", null, [
                        _createTextVNode("Die "),
                        _createElementVNode("strong", null, "Eingabe Ihrer Kreditkartendaten"),
                        _createTextVNode(" erfolgt im nächsten Schritt, "),
                        _createElementVNode("strong", null, "nach Abschluß der Buchung."),
                        _createTextVNode(" Dabei werden Sie, wie im Rahmen der neuen Zahlungsdiensterichtlinie (PSD2) festgelegt, "),
                        _createElementVNode("strong", null, "für die Authentifizierung zu Ihrer Bank weitergeleitet, um die Zahlung des Gesamtbetrags zu bestätigen."),
                        _createTextVNode(" Danach werden Sie wieder zu uns zurückgeleitet.")
                      ], -1 /* HOISTED */)
                    ])))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", _hoisted_9, [
      _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.paymentOptions['DirectDebitInternational']?.header ?? 'Zahlung per Lastschriftmandat'), 1 /* TEXT */),
      (selectedPaymentIsSepa.value && _ctx.paymentOptions['DirectDebitInternational'].serviceText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.paymentOptions['DirectDebitInternational'].serviceText), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createVNode(CheckoutSepaFormFields, {
        "unique-key": "sepa",
        "validate-form": selectedPaymentIsSepa.value
      }, null, 8 /* PROPS */, ["validate-form"])
    ], 512 /* NEED_PATCH */), [
      [_vShow, selectedPaymentIsSepa.value]
    ]),
    (selectedPaymentOption.value?.header)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_12, _toDisplayString(selectedPaymentOption.value.header), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (selectedPaymentOption.value?.serviceText && !selectedPaymentIsSepa.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(selectedPaymentOption.value?.serviceText), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (!_ctx.isPaymentPage)
      ? (_openBlock(), _createBlock(CheckoutPaymentInfo, {
          key: 4,
          "price-info": priceInfoFromStore.value,
          "flex-storno-amount": _ctx.flexStornoAmount,
          "start-date": _ctx.startDate
        }, null, 8 /* PROPS */, ["price-info", "flex-storno-amount", "start-date"]))
      : _createCommentVNode("v-if", true),
    (isMobile.value)
      ? (_openBlock(), _createBlock(CheckoutPaymentMethodInfo, { key: 5 }))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})