<template>
	<div class="list-filters">
		<h2 class="list-filters__header">
			Suchergebnisse filtern
		</h2>
		<div>
			<div class="list-filters__content">
				<FilterContainer
					v-if="isHotelListPage"
					:title="dealsFilterTitle"
					:with-divider="false"
				>
					<DealsFilter class="list-filters__deals" />
				</FilterContainer>
				<FilterContainer
					:with-divider="isHotelListPage"
				>
					<FlexFilter />
				</FilterContainer>
				<FilterContainer
					v-if="!isHotelOnly"
				>
					<DirectFlightFilter title="Flug" class="list-filters__direct-flight"/>
					<FlyingTimeFilter class="list-filters__flight" />
				</FilterContainer>
				<FilterContainer
					v-if="!isOfferList"
				>
					<PriceFilter
						:default-value="formData.maxPrice"
						:currency="currencyType"
					/>
				</FilterContainer>
				<FilterContainer>
					<RoomTypeFilter
						:is-hotel-page="isOfferList"
						class="list-filters__room-type"
					/>
				</FilterContainer>
				<FilterContainer
				>
					<SeaViewFilter />
				</FilterContainer>
				<FilterContainer>
					<BoardTypeFilter
						:is-hotel-page="isOfferList"
						class="list-filters__board-types"
					/>
				</FilterContainer>
				<FilterContainer
					v-if="!isOfferList"
				>
					<MostPopularFilters
						class="list-filters__most-popular"
						:filters="formData.mostPopularFilters"
					/>
				</FilterContainer>
				<FilterContainer
					v-if="!isOfferList"
				>
					<HotelCategoryFilter
						:show-suns="showSuns"
					/>
				</FilterContainer>
				<FilterContainer
					v-if="!isOfferList"
				>
					<RecommendationRateFilter/>
				</FilterContainer>
				<FilterContainer
					v-if="!isOfferList"
				>
					<CustomerRatingFilter/>
				</FilterContainer>
				<FilterContainer>
					<HotelTransferFilter />
				</FilterContainer>
				<FilterContainer
					v-if="showDestinationFilter"
				>
					<SkeletonLoader
						v-if="destinationFilterLoading"
						class="list-filters__geo-skeleton"
						:loader-width="31.8"
						:loader-height="7"
						:border-radius="'1rem'"
					/>
					<DestinationFilter
						v-else
						:destination-label="destinationLabel"
						:location-type="locationType"
						:disabled="!destinationTypes || destinationTypes.length == 0"
					/>
				</FilterContainer>
				<FilterContainer>
					<OperatorField
						v-if="!tourOperatorLoading"
						class="list-filters__operator"
						:disabled="!operatorTypes || operatorTypes.length === 0"
					/>
					<SkeletonLoader
						v-if="tourOperatorLoading"
						class="list-filters__operator-skeleton"
						:loader-width="31.8"
						:loader-height="7"
						:border-radius="'1rem'"
					/>
				</FilterContainer>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>

import BoardTypeFilter from '@lmt-rpb/BoardTypeFilter/BoardTypeFilter.vue'
import HotelTransferFilter from '@lmt-rpb/HotelTransferFilter/HotelTransferFilter.vue';
import RecommendationRateFilter from '@lmt-rpb/RecommendationRateFilter/RecommendationRateFilter.vue';
import PriceFilter from '@lmt-rpb/PriceFilter/PriceFilter.vue';
import HotelCategoryFilter from '@lmt-rpb/HotelCategoryFilter/HotelCategoryFilter.vue';
import SeaViewFilter from '@lmt-rpb/SeaViewFilter/SeaViewFilter.vue';
import CustomerRatingFilter from '@lmt-rpb/CustomerRatingFilter/CustomerRatingFilter.vue';
import DestinationFilter from '@lmt-rpb/DestinationFilter/DestinationFilter.vue';
import DirectFlightFilter from '@lmt-rpb/DirectFlightFilter/DirectFlightFilter.vue';
import FlyingTimeFilter from '@lmt-rpb/FlyingTimeFilter/FlyingTimeFilter.vue';
import RoomTypeFilter from '@lmt-rpb/RoomTypeFilter/RoomTypeFilter.vue';
import MostPopularFilters from '@lmt-rpb/MostPopularFiltersNew/MostPopularFiltersNew.vue';
import OperatorField from '@lmt-rpb/OperatorField/OperatorField.vue';
import type { SearchFormDataType } from '@interfaces/search-form';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import FilterContainer from '@lmt-rpb/FilterContainer/FilterContainer.vue';
import {
	computed, onMounted, ref,
} from 'vue';
import FlexFilter from '@lmt-rpb/FlexFilter/FlexFilter.vue';
import DealsFilter from '@lmt-rpb/Organisms/DealsFilter/DealsFilter.vue';
import { dealsFilterClientTitle } from '@lmt-rpb/Organisms/DealsFilter/dealsFilterHelper';
import { useStore } from '@/components/common/store';
import { isClientGermany } from '@/js/utils/environmentUtils';

interface Props {
	forceDesktop?: boolean
	destinationLabel?: string
	listType: 'hotel' | 'region' | 'offer',
}

const props = withDefaults(defineProps<Props>(), {
	forceDesktop: false,
	destinationLabel: '',
});

const dealsFilterTitle = dealsFilterClientTitle();

const store = useStore();
const formData = computed((): SearchFormDataType => store.state.searchMask);
const locationType = computed((): string => store.state.config.locationType);

const showDestinationFilter = computed((): boolean => props.listType === 'hotel' && locationType.value !== 'CITY');
const destinationTypes = computed(() => store.state.types.destination);

const isHotelListPage = computed(() => props.listType === 'hotel');
const isOfferList = computed(() => props.listType === 'offer');

const openAdditionalFilters = ref(Boolean(formData.value.additionalCheckboxFilters?.ratingAttributes?.length || formData.value.additionalCheckboxFilters?.hotelAttributes?.length));

const isHotelOnly = computed((): boolean => formData.value.onlyHotel);

const operatorTypes = computed(() => store.state.types.operator);

const showSuns = isClientGermany();

const currencyType = computed(() => (store.getters.getCurrencyType));

const tourOperatorLoading = computed((): boolean => store.state.tourOperatorLoading);

const destinationFilterLoading = computed((): boolean => store.state.destinationFilterLoading);

const setAccommodationType = (onlyHotel: boolean): void => {
	store.commit('searchMask/updateFormData', { onlyHotel });
};

onMounted(() => {
	// this is needed for setting the isDesktop property to true in Storybook
	if (props.forceDesktop) {
		store.state.config.isDesktop = true;
	}

	if (isHotelOnly.value) {
		setAccommodationType(true);
	}
});
</script>

<style lang="scss" scoped>

:deep() {
	.range__slider {
		// half of the circle minus slider height
		margin-bottom: 1.2rem;
	}

	.price-slider__value {
		margin-top: 0;
	}

	.checkbox {
		font-weight: $font-weight-regular;
	}

	.flying-time-slider__inputs {
		margin-left: 0.8rem;
	}
}

.list-filters {
	width: 100%;

	@media (min-width: $breakpoint-extralarge) {
		width: unset;
	}
}

.list-filters__header {
	text-align: left;
	background-color: $color-primary;
	color: $color-white;
	font-size: $font-medium-1;
	font-family: $font-family-special;
	font-weight: $font-weight-bold;
	padding: 1.6rem 2.4rem;
	border-top-left-radius: $border-radius-filter;
	border-top-right-radius: $border-radius-filter;

	@media screen and (max-width: $breakpoint-extralarge - 1) {
		display: none;
	}
}

.list-filters__direct-flight {
	margin-bottom: 2.4rem;
}

.list-filters__deals {
	margin-left: 0.8rem;
}

.list-filters__operator-skeleton,
.list-filters__geo-skeleton {
	border: 1px solid $filter-border-color;
}

:deep(.direct-flight-filter),
:deep(.flying-time-filter) {
	padding: 0;
}

:deep(.flying-time-filter__sliders) {
	margin-left: 0;
	padding: 0;
}

@media screen and (max-width: $breakpoint-extralarge) {
	.list-filters__flight {
		:deep(.flying-time-slider__header) {
			padding-left: 0.8rem;
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.list-filters__content {
		padding: 1.6rem;
		border-bottom-left-radius: $border-radius-filter;
		border-bottom-right-radius: $border-radius-filter;

		// TODO: $color-filter-bg variable is #f9f9f9 for kuoni
		// check if it always should be #FFFAF5 because then it is the same as $color-checkout-wrapper and probably some others as well
		background: $color-checkout-wrapper;
		border-top: 0;
	}
}
</style>
