<template>
	<div
		class="theme-minimal"
		@click.prevent="$emit('ThemeMinimal:Edit')"
	>
		<div
			class="theme-minimal__form"
			autocomplete="true"
		>
			<div class="theme-minimal__values">
				<span class="theme-minimal__destination">{{ destination }}</span>
				<div>
					<span class="theme-minimal__rest">{{ searchMaskData }}</span>
				</div>
			</div>
			<i :class="['theme-minimal__svg', iconName]"></i>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { NBSP } from '@global-js/constants';
import { pluralize, getTravelDuration, formatDateInterval } from '@utils/utils';
import { SearchFormDataType } from '@interfaces/search-form';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { useStore } from '@/components/common/store';

interface Props {
	iconName?: string
}

const props = withDefaults(defineProps<Props>(), {
	iconName: 'fa-solid fa-pen'
});

const store = useStore();

const isMinTablet = computed((): boolean => store.state.config.isMinTablet);

const formData = computed((): SearchFormDataType => store.state.searchMask);

const destination = computed((): undefined | null | string => formData.value.destination?.label || '');

const offerDuration = computed((): string => {
	const { from, to } = formData.value.offerDuration;
	return formatDateInterval(from, to);
});

const travelers = computed((): string => {
	const { adult, children } = formData.value.travelers;
	const adultLabel = (!isMinTablet.value && `${adult}${NBSP}Erw.`) || pluralize(adult, 'Erwachsener', 'Erwachsene');
	let travelersLabel = `${adultLabel}`;
	if (children.length) {
		travelersLabel += ', ' + pluralize(children.length, 'Kind', 'Kinder');
		travelersLabel += `${NBSP}(${children.map((c) => ((c === 1 ? '<2' : c) + NBSP + 'J.')).join(',' + NBSP)})`;
	}
	return travelersLabel;
});

const getSerchMaskData = () => {
	let result = '';
	if (offerDuration.value) {
		result = offerDuration.value + ' | ';
	}
	if (formData.value.travelDuration) {
		result += getTravelDuration(formData.value.travelDuration) + ' | ';
	}
	result += travelers.value;
	return result;
};

const searchMaskData = ref(getSerchMaskData());

watch(isMinTablet, () => {
	searchMaskData.value = getSerchMaskData();
});

watch(formData.value, () => {
	searchMaskData.value = getSerchMaskData();
});

</script>

<style lang="scss" scoped>
.theme-minimal {
	@include media-query-up($breakpoint-large) {
		justify-content: center;
		font-size: $font-small-2;
	}

	& {
		display: flex;
		justify-content: flex-start;
		height: 7.2rem;
		width: 100%;
		padding: 0 2.66%;
		font-size: $font-small-3;
		cursor: pointer;
		background-color: $header-bar-bg;
	}

	.theme-minimal__form {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: $breakpoint-container;
		border: 0.1rem solid $color-border;
		border-radius: $border-radius-theme-minimal;
		padding: 1rem 1.6rem;
		background-color: $color-white;
		margin: 0.8rem 0;
	}

	.theme-minimal__values {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.theme-minimal__svg {
		color: $color-primary;
		font-size: 2rem;
	}

	.theme-minimal__rest {
		padding-right: 1.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;

		@media (min-width: $breakpoint-small) {
			font-size: $font-small-2;
		}
	}

	.theme-minimal__destination {
		@include media-query-up($breakpoint-large) {
			display: inline-block;
			max-width: 60rem;
		}

		& {
			white-space: nowrap;
			margin: 0 2rem 0.25rem 0;
			font-family: $font-family-special;
			font-weight: $font-weight-semibold;
			text-overflow: ellipsis;
			overflow: hidden;
			color: $color-text;
		}

		@media (min-width: $breakpoint-small) {
			font-size: $font-small-2;
		}
	}

	.theme-minimal__travelduration {
		@include visible-from($breakpoint-large, inline-block);
	}
}
</style>
