<template>
	<div class="filter-type">
		<div class="filter-type__header" :class="{ desktop: isDesktop }" @click="toggleDropdown">
			<FilterHeader
				:title="title"
				:icon="icon"
				:icon-label="ariaLabel"
			/>
			<BaseIcon v-if="!isDesktop" name="chevron" class="filter-type__chevron"
				:class="{ toggled: dropdownOpen }" />
		</div>
		<div
			v-if="isDesktop"
			class="filter-type__body--desktop"
		>
			<slot />
		</div>
		<TransitionExpand
			v-else
			class="filter-type__body--mobile"
			:show-content="dropdownOpen"
		>
			<slot />
		</TransitionExpand>
	</div>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import TransitionExpand from '@lmt-rpb/TransitionExpand/TransitionExpand.vue';
import { ref, computed } from 'vue';
import { useStore } from '@/components/common/store';
import FilterHeader from '@lmt-rpb/FilterHeader/FilterHeader.vue';

interface Props {
	icon?: string
	title?: string
	initialOpen?: boolean
	ariaLabel?: string
}

const props = withDefaults(defineProps<Props>(), {
	icon: undefined,
	title: 'Initial title',
	initialOpen: true
});

const emit = defineEmits(['FilterType:MobileHeaderOpened']);

const store = useStore();
const isDesktop = computed(() => store.state.config.isDesktop);

const dropdownOpen = ref(props.initialOpen);
const toggleDropdown = function() {
	if (isDesktop.value) return;
	dropdownOpen.value = !dropdownOpen.value;
	if (dropdownOpen.value) {
		emit('FilterType:MobileHeaderOpened');
	}
};

</script>

<style lang="scss" scoped>
.filter-type {
	&__header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		column-gap: 0.8rem;
		margin-block: 0 0.8rem;
		padding: 0;
		cursor: pointer;

		&.desktop {
			background-color: initial;
			box-shadow: none;
			padding: 0;
			margin-block: 0 1.9rem;
			cursor: default;

			.filter-type__title {
				font-size: $font-medium-2;
				font-weight: $font-weight-semibold;
				line-height: 2.724rem;
			}
		}
	}

	&__title {
		color: $color-filter-type-header-text;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		margin-bottom: 0;
	}

	&__title-icon {
		width: 3rem;
		height: 3rem;
		fill: $color-filter-type-collapse-icon;
	}

	&__chevron {
		width: 1.8rem;
		height: 0.9rem;
		transform: rotate(180deg);
		transition: transform 0.1s ease-out;
		vertical-align: middle;
		fill: $color-filter-type-collapse-icon;
		margin-left: auto;

		&.toggled {
			transform: rotate(360deg);
		}
	}

	&__body--mobile {
		overflow: hidden;
		position: relative;
		padding: 0;

		&::before {
			content: '';
			display: block;
			height: 0.8rem;
		}
	}
}

</style>
