<template>
	<div class="filter-radio-button">
		<input
			:id="`${id}-${value}`"
			v-model="model"
			:disabled="disabled"
			type="radio"
			class="filter-radio-button__input"
			role="radio"
			:aria-checked="isChecked"
			:aria-label="label"
			:value="value"
			:checked="isChecked"
		/>
		<span>
			<label
				class="filter-radio-button__label"
				:for="`${id}-${value}`"
			>
				<b
					v-if="boldLabel"
					class="filter-radio-button__bold-label"
				>{{ boldLabel }}</b>{{ label }}</label> <slot name="tooltip"></slot>
		</span>
	</div>
</template>

<script lang="ts" setup generic="T extends string | number | undefined">
import { computed } from 'vue';

interface Props {
	id: string
	value: T
	disabled?: boolean
	label?: string
	boldLabel?: string
}

const props = withDefaults(defineProps<Props>(), {
	id: 'default',
	disabled: false,
	label: '',
	boldLabel: undefined
});

const model = defineModel<T>();

const isChecked = computed(() => model.value === props.value);
</script>

<style lang="scss" scoped>
.filter-radio-button {
	display: inline-flex;
	align-items: flex-start;
	column-gap: 1.6rem;

	&__label {
		display: inline;
		margin-bottom: 0;
		color: $color-text;
		font-size: $font-small-1;
		font-weight: $font-weight-regular;
		cursor: pointer;
	}

	&__bold-label {
		font-weight: $font-weight-semibold;
	}

	&__input {
		margin-top: 0;
		margin-inline: 0;
		position: relative;
		appearance: none;
		border-radius: 50%;
		width: 2.4rem;
		height: 2.4rem;
		border: solid 0.25rem $color-border;
		cursor: pointer;
		background-color: $color-white;
		flex-shrink: 0;

		&:focus,
		&:focus-visible {
			outline: none;
		}

		&:disabled {
			border-color: $color-inactive;
			cursor: not-allowed;

			& + span .filter-radio-button__label {
				color: $color-inactive;
				cursor: not-allowed;
			}
		}
	}

	&__input:checked {
		border-width: 0.2rem;

		&::after {
			content: '';
			width: 1.4rem;
			height: 1.4rem;
			border-radius: 50%;
			background-color: $color-filter-radio-button;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&:disabled::after {
			background-color: $color-inactive;
		}
	}
}
</style>
