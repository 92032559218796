<template>
	<div
		:class="['search-bar-core', blockClassModifiers]"
	>
		<form
			class="search-bar-core__form"
			autocomplete="false"
			@submit.prevent="onSubmit"
		>
			<TravelTypeSwitch class="search-bar-core__switch"/>
			<div class="search-bar-core__fields">
				<DestinationField
					class="search-bar-core__destination-field"
				/>
				<ExpandTransition :direction="isDesktop ? 'width' : 'height'">
					<AirportFilter
						v-if="!isHotelOnly"
						class="search-bar-core__airport-filter"
						:client
					/>
				</ExpandTransition>
				<OfferDurationField
					class="search-bar-core__offer-duration-field"
				/>
				<TravelDurationField
					class="search-bar-core__travel-duration-field"
				/>
				<TravelersField
					class="search-bar-core__passengers-field"
				/>
				<RoundedButton
					v-if="shouldShowSubmitButton"
					type="submit"
					theme="extra"
					class="search-bar-core__submit-button"
					:disabled="maxTravelDurationError"
					@keydown.enter.prevent="onSubmit"
					@keydown.space.prevent="onSubmit"
				>
					<span class="search-bar-core__submit-button-text">{{ submitButtonText }}</span>
				</RoundedButton>
			</div>

		</form>
	</div>
</template>

<script lang="ts" setup>

import {
	SearchFormDataType,
} from '@interfaces/search-form';
import AirportFilter from '@lmt-rpb/AirportFilter/AirportFilter.vue';
import DestinationField from '@lmt-rpb/DestinationField/DestinationField.vue';
import OfferDurationField from '@lmt-rpb/OfferDurationField/OfferDurationField.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import TravelDurationField from '@lmt-rpb/TravelDurationField/TravelDurationField.vue';
import TravelersField from '@lmt-rpb/TravelersField/TravelersField.vue';
import {
	scrollIt,
} from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import {
	computed, onMounted, ref
} from 'vue';
import { useStore } from '@/components/common/store';
import TravelTypeSwitch from '../TravelTypeSwitch/TravelTypeSwitch.vue';
import ExpandTransition from '@lmt-rpb/Atoms/ExpandTransition/ExpandTransition.vue';

interface Props {
	variant?: 'standalone' | 'inline';
	showSubmitButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	variant: 'standalone',
	showSubmitButton: true,
});

const emit = defineEmits(['searchSubmit']);

const store = useStore();

const isDesktop = computed((): boolean => store.state.config.isDesktop);
const formData = computed((): SearchFormDataType => store.state.searchMask);
const isHotelOnly = computed((): boolean => formData.value.onlyHotel);

const pageType = computed((): string => store.state.config.pageType);
const shouldShowSubmitButton = computed((): boolean => {
	if(['hotelPage', 'hotelList', 'regionList'].includes(pageType.value)) {
		return false;
	}
	return props.showSubmitButton;
});

const maxTravelDurationError = computed(() => store.state.searchMask.maxTravelDurationError);

const client = computed(() => store.state.config.client);

const submitButtonText = 'Suchen';

const blockClassModifiers = computed(() => ({
	[`search-bar-core--${props.variant}`]: Boolean(props.variant),
}));

const scrollUpSearchForm = (): void => {
	const searchForm: HTMLElement | null = document.querySelector('#search-form-container .search-form');
	if (!searchForm) {
		return;
	}

	const spaceSearchToBottom = (window.innerHeight - searchForm?.getBoundingClientRect().bottom);

	if (spaceSearchToBottom < 450) {
		scrollIt(
			searchForm,
			300,
			'easeOutQuad',
		);
	}
};

const onSubmit = (): void => {
	emit('searchSubmit', formData.value);
};

onMounted(() => {
	EventBus.$on('OfferSearch:ScrollUpSearchForm', scrollUpSearchForm);
});

</script>

<style lang="scss" scoped>
.search-bar-core {
	width: 100%;
	margin: 0 auto;
	padding: 1.6rem 0;

	&__form {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		grid-template-columns: 1fr 12.2rem;
		gap: 1.6rem;
	}

	&__switch {
		position: relative;
		left: -1.6rem;
		right: -1.6rem;
		min-width: calc(100% + 3.2rem);
		padding: 0 1.6rem;

		@include horizontal-hidden-scroll;
	}

	&__fields {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
		gap: 0.8rem;
		grid-column: 1/-1;

		& > * {
			width: 100%;
		}
	}

	&__submit-button {
		width: auto;
		margin-top: 0.8rem;
		display: block;
	}

	&__offer-duration-field, &__travel-duration-field {
		width: calc(50% - 0.4rem);
	}

	&__travel-duration-field {
		width: min-content;
		flex-grow: 1;
	}

	&__offer-duration-field {
		min-width: 16rem;
	}

	&--standalone {
		background-color: $color-white;
		padding: 1.6rem;
		border-radius: $border-radius-main-search;
		box-shadow: 0.3rem .6rem 1.4rem 0 #66666633;
	}

	@include media-query-up($breakpoint-small) {
		&--standalone {
			padding: 2.4rem;
		}
	}

	@include media-query-up($breakpoint-extralarge) {
		max-width: 100%;

		&__offer-duration-field, &__travel-duration-field {
			flex: 1;
		}

		&__fields {
			flex-wrap: nowrap;

			& > * {
				flex: 1;
				width: auto;
			}
		}

		&__submit-button {
			max-width: 12.2rem;
			margin-top: 0;
		}
	}

}
</style>
