import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "chip-base__icon" }
const _hoisted_2 = { class: "chip-base__label" }
const _hoisted_3 = ["type", "name", "value", "aria-label"]

import { computed } from 'vue';
import CloseButton from '../CloseButton/CloseButton.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import type { IconNames } from '@lmt-rpb/BaseIcon/svg';

interface Props {
	iconName?: IconNames;
	label: string;
	withDelete?: boolean;
	selected?: boolean;
	name?: string;
	value?: TVal;
	as?: 'div' | 'checkbox' | 'radio';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChipBase',
  props: /*@__PURE__*/_mergeModels({
    iconName: {},
    label: {},
    withDelete: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    name: {},
    value: {},
    as: { default: () => (undefined) }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['delete-click', 'click'], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {


const emit = __emit;
const model = _useModel(__props, "modelValue");

const isSelector = computed(() => __props.as === 'checkbox' || __props.as === 'radio');
const parentElement = computed(() => isSelector.value ? 'label' : 'div');
const defaultAttributes = computed(() => {
	if (!isSelector.value) {
		return {
			role: 'button',
			tabindex: 0,
			'aria-label': __props.label,
			'aria-selected': __props.selected,
			'key-dow': onKeyDown,
		};
	}
	return {};
});

const defaultListeners = computed(() => {
	if (!isSelector.value) {
		return {
			keydown: onKeyDown,
		};
	}
	return {};
});

const blockClassModifiers = computed(() => ({
	'chip-base--selected': __props.selected,
	'chip-base--selector': isSelector.value,
}));

const onChipClick = () => {
	emit('click');
};

function onKeyDown(event: KeyboardEvent) {
	if (event.key === 'Enter' || event.key === ' ') {
		onChipClick();
	}
};

const onDelete = () => {
	emit('delete-click');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(parentElement.value), _mergeProps({
    class: ['chip-base', blockClassModifiers.value]
  }, defaultAttributes.value, { onClick: onChipClick }, _toHandlers(defaultListeners.value)), {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "icon", {}, () => [
          (_ctx.iconName)
            ? (_openBlock(), _createBlock(BaseIcon, {
                key: 0,
                name: _ctx.iconName,
                "aria-hidden": "true"
              }, null, 8 /* PROPS */, ["name"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "label", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)
        ])
      ]),
      _renderSlot(_ctx.$slots, "default"),
      (isSelector.value)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
            type: _ctx.as,
            class: "chip-base__input",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model).value = $event)),
            name: _ctx.name,
            value: _ctx.value,
            "aria-label": _ctx.label
          }, null, 8 /* PROPS */, _hoisted_3)), [
            [_vModelDynamic, model.value]
          ])
        : _createCommentVNode("v-if", true),
      (_ctx.withDelete)
        ? (_openBlock(), _createBlock(CloseButton, {
            key: 1,
            class: "chip-base__delete",
            "inherit-styles": "",
            "aria-label": `Delete ${_ctx.label}`,
            onClick: _withModifiers(onDelete, ["stop"])
          }, null, 8 /* PROPS */, ["aria-label"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["class"]))
}
}

})